@import "src/scss/module";

.header {
  position: relative;
  min-height: 280px;
  background-color: var(#{--bs-light});
  display: flex;
  flex-direction: column;
  justify-content: center;

  &[class*="has-img"] {
    overflow: hidden;
    justify-content: center;
    min-height: 480px;
    text-shadow: 1px 1px 4px rgba(black, .25);

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba($card-img-overlay-color, 0) 0%, rgba($card-img-overlay-color, 0.5) 100%);
      z-index: 2;
    }
  }

  &[class*="has-img"] &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    object-fit: cover;
    z-index: 1;
  }

  &[class*="has-img"] &__body {
    position: relative;
    z-index: 3;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
}
