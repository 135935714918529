@import "src/scss/module";

.header {
  background-color: var(#{--bs-light});
  img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    object-position: left center;

    @include media-breakpoint-up(md) {
      width: 50vw;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      min-height: 480px;
    }
  }

  &:global {
    &.not-found-page {
      .row {
          flex-direction: row-reverse;
      }
      .description {
        @include media-breakpoint-up(md) {
          padding-left: 4rem;
        }
      }
      img {
        width: 100%;
        object-position: center;
      }
      h2 {
        font-weight: 400;
        @include media-breakpoint-up(md) {
          font-size: 3.7rem;
        }
      }
      .btn {
        background-color: white;
        color: #212529;
        border-radius: 5rem;
        padding: 0.5rem 1.8rem;
        margin: 3rem 0 2rem 0;
        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
        }
        svg {
          display: none !important;
        }
        &:hover {
          background-color: #212529;
          color: white;
        }
      }
    }
  }
}